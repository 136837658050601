/* eslint-disable */
import { TranslateFunction } from '@noahswap/localization'

/**
 * This is hacking out the revert reason from the ethers provider thrown error however it can.
 * This object seems to be undocumented by ethers.
 * @param error an error from the ethers provider
 * @param t Translation function
 * @param page
 */
export function transactionErrorToUserReadableMessage(error: any, t: TranslateFunction, page?: any) {
  let reason: string | undefined
  while (error) {
    reason = error.reason ?? error.data?.message ?? error.message ?? error ?? reason
    // eslint-disable-next-line no-param-reassign
    error = error.error ?? error.data?.originalError
  }

  if (reason?.indexOf('execution reverted: ') === 0) reason = reason.substring('execution reverted: '.length)
  if (reason?.indexOf('cancel') === 0) {
    return 'user rejected transaction'
  }

  if (page === 'bridge') {
    if (reason == undefined) {
      return 'An unknown RPC erroroccurred.'
    }
    if (reason?.indexOf('user rejected transaction') === 0) {
      console.log('user rejected')
      return 'user rejected transaction'
    }
    const dataMatch = /"data":\s*{[^}]*"message":\s*"([^"]*)"/.exec(reason)
    if (dataMatch && dataMatch[1]) {
      const dataMessage = dataMatch[1]
      // console.log(dataMessage);
      return dataMessage
    }
    return reason
  } else {
    switch (reason) {
      case 'NoahRouter: EXPIRED':
        return t(
          'The transaction could not be sent because the deadline has passed. Please check that your transaction deadline is not too low.',
        )
      case 'NoahRouter: INSUFFICIENT_OUTPUT_AMOUNT':
      case 'NoahRouter: EXCESSIVE_INPUT_AMOUNT':
      case 'NoahRouter: INSUFFICIENT_A_AMOUNT':
      case 'NoahRouter: INSUFFICIENT_B_AMOUNT':
        return t('This transaction will not succeed either due to price movement or fee on transfer.')
      case 'TransferHelper: TRANSFER_FROM_FAILED':
        return t('The input token cannot be transferred. There may be an issue with the input token.')
      case 'Noah: TRANSFER_FAILED':
        return t('The output token cannot be transferred. There may be an issue with the output token.')
      default:
        if (reason?.indexOf('undefined is not an object') !== -1) {
          console.error(error, reason)
          if (page === 'swap') {
            return t(
              'An error occurred when trying to execute this operation. You may need to increase your slippage tolerance. If that does not work, there may be an incompatibility with the token you are trading.',
            )
          } else {
            return t('An error occurred when trying to execute this operation.')
          }
        }
        return t('%reason%.', {
          reason: reason ? `: "${reason}"` : '',
        })
    }
  }
}
